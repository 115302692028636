import React from "react"

const CvBtnJa = {
  cvText: "さらに詳しく知りたい方はこちら",
  cvBtn: "資料をダウンロード（無料）",
}

const CvBtnUs = {
  cvText: "Click here to learn more.",
  cvBtn: "Download our materials (free)",
}

const CvBtnZh = {
  cvText: "更多信息",
  cvBtn: "下载文件(免费)",
}

export const getCvBtn = lang => {
  switch (lang) {
    case "":
      return (
        <>
          <section className="hs__cv">
            <p className="hs__cv-text">{CvBtnJa.cvText}</p>
            <a
              className="hs__cv-btn"
              href="https://hub.park-lot.com/hashtag-document-download"
              target="_blank"
              rel="noreferrer"
            >
              {CvBtnJa.cvBtn}
            </a>
          </section>
        </>
      )
    case "en-US":
      return (
        <>
          <section className="hs__cv">
            <p className="hs__cv-text">{CvBtnUs.cvText}</p>
            <a
              className="hs__cv-btn"
              href="https://hub.park-lot.com/hashtag-document-download"
              target="_blank"
              rel="noreferrer"
            >
              {CvBtnUs.cvBtn}
            </a>
          </section>
        </>
      )
    case "zh-CN":
      return (
        <>
          <section className="hs__cv">
            <p className="hs__cv-text">{CvBtnZh.cvText}</p>
            <a
              className="hs__cv-btn"
              href="https://hub.park-lot.com/hashtag-document-download"
              target="_blank"
              rel="noreferrer"
            >
              {CvBtnZh.cvBtn}
            </a>
          </section>
        </>
      )
    default:
      break
  }
}
