import React from "react"
import { getCvBtn } from "../../modules/cv-btnMenus"

export const CvBtn = ({ lang = "" }) => {
  return (
    <>{getCvBtn(lang)}</>
    // <section className="hs__cv">
    //   <p className="hs__cv-text">さらに詳しく知りたい方はこちら</p>
    //   <a
    //     className="hs__cv-btn"
    //     href="https://hub.park-lot.com/hashtag-document-download"
    //     target="_blank"
    //     rel="noreferrer"
    //   >
    //     資料をダウンロード（無料）
    //   </a>
    // </section>
  )
}
