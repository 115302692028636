import React, { useState } from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
// import { StaticImage } from "gatsby-plugin-image"
import { CvBtn } from "components/hashtag/cv-btn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { Service } from "components/service"
// import { SampleCardsA } from "components/hashtag/sample-cards-a"
import { SampleCardsB } from "components/hashtag/sample-cards-b"
import { DefaultHeader } from "../../components/headers/default-header"

const HashtagCampaign = ({ location }) => {
  /** sampleタブ表示切り替え */
  // const [isShowSampleA, setIsShowSampleA] = useState(true)
  // const [isShowSampleB, setIsShowSampleB] = useState(true)
  // const activeStyle = {
  //   borderBottom: "solid 4px #4bc2bb",
  //   color: "#4bc2bb",
  // }
  // const inActiveStyle = {
  //   borderBottom: "solid 1px #ededed",
  //   color: "#ededed",
  // }
  // const onClickShowSampleA = () => {
  //   setIsShowSampleA(true)
  //   setIsShowSampleB(false)
  // }
  // const onClickShowSampleB = () => {
  //   setIsShowSampleA(false)
  //   setIsShowSampleB(true)
  // }
  /** UGC説明 */
  const [isShowUgc, setShouldShowUgc] = useState(false)
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="InstagramのUGC収集・掲載 | ハッシュタグキャンペーン"
          pagedesc="Instagramのハッシュタグ付き投稿を自動で収集＆表示。ハッシュタグキャンペーンに最適なPARKLoTのサービス内容について、掲載までの流れ、豊富な機能、お得な料金をご紹介します。"
          pagepath={location.pathname}
        />
        <main className="hashtag">
          <section className="hs__fv">
            <div className="hs__fv-container">
              <div className="hs__fv-text-inner">
                <div className="hs__fv-catch-container">
                  <p className="hs__fv-catch">衝撃価格</p>
                  <p className="hs__fv-catch">最短1日から</p>
                </div>
                <h1 className="hs__fv-h1">
                  <span>既存サイトで手間なくUGCを収集・掲載！</span>
                  <img
                    layout="constrained"
                    alt="既存サイトで手間なくUGCを収集・掲載！"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/hs-h1.png"
                  />
                </h1>
                <p className="hs__fv-lead">
                  Instagramのハッシュタグ付き投稿を自動で収集&表示。ハッシュタグキャンペーンに最適！
                </p>
              </div>
              <a
                className="hs__fv-cv-btn"
                href="https://hub.park-lot.com/hashtag-document-download"
                target="_blank"
                rel="noreferrer noopener"
              >
                資料をダウンロード（無料）
              </a>
              <div className="hs__fv-img-container">
                <div className="hs__fv-img">
                  <img
                    layout="constrained"
                    alt="既存サイトで手間なくUGCを収集・掲載！"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/hs-fv.png"
                  />
                </div>
                <div className="hs__fv-ugc-btn-container">
                  <button
                    className="hs__fv-ugc"
                    type="button"
                    onClick={() => setShouldShowUgc(prevState => !prevState)}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
                    UGCとは？
                  </button>
                  {isShowUgc && (
                    <div className="hs__fv-ugc-detail-container">
                      UGC（User Generated
                      Contents）は一般ユーザーによって生成された、InstagramなどのSNSに投稿された写真・動画コンテンツを指します。
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="hs__flow">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Flow</h2>
                <p className="hs__subtitle">掲載までの流れ</p>
                <p className="hs__lead">
                  面倒な収集・掲載作業はゼロ。
                  <br />
                  UGCを手間なく簡単に実施できます。
                </p>
              </div>
              <div className="hs__flow-container">
                <div className="hs__flow-card">
                  <img
                    layout="constrained"
                    alt="投稿を自動収集"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/hs-flow_01.jpg"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">投稿を自動収集</h3>
                    <p>
                      ハッシュタグをもとに投稿を自動集計します。動画、テキスト、画像など全て収集可能です。（Instagram対応）
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <img
                    layout="constrained"
                    alt="収集した投稿の管理"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/hs-flow-02-re.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">収集した投稿の管理</h3>
                    <p>
                      取集したコンテンツを管理画面から確認できます。また、各投稿の表示機能で表示非表示の設定が簡単に設定できます。
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <img
                    layout="constrained"
                    alt="投稿の埋め込み"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/hs-flow-03.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">投稿の埋め込み</h3>
                    <p>
                      自社サイトやキャンペーンLPに簡単に埋め込みが可能です。ユーザーのリアルタイムの投稿を自動で掲載できます。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hs__feature">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Feature</h2>
                <p className="hs__subtitle">機能</p>
                <p className="hs__lead">
                  収集から掲載までの作業コストを
                  <br />
                  大幅にカットできる機能が揃っています。
                </p>
              </div>
              <div className="hs__feature-container">
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="ハッシュタグ収集（Instagram対応）"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/collect.png"
                  />
                  <h3 className="hs__feature-card-title">
                    ハッシュタグ収集
                    <br />
                    <span>（Instagram対応）</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    特定のハッシュタグの投稿を収集します。テキスト、画像、動画も収集できます。最大4種類のハッシュタグまで設定可能です。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="レポート抽出"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/report.png"
                  />
                  <h3 className="hs__feature-card-title">
                    レポート抽出
                    <br />
                  </h3>
                  <p className="hs__feature-card-text">
                    投稿を分析する上で重要な指標となる、インプレッションやエンゲージメントなど各種の数字を確認できます。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="既存サイトへの埋め込み対応"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/embed.png"
                  />
                  <h3 className="hs__feature-card-title">
                    既存サイトへの
                    <br />
                    埋め込み対応
                  </h3>
                  <p className="hs__feature-card-text">
                    自社サイトやキャンペーンサイトに当社が発行するタグを埋め込むだけでUGCを掲載可能です。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="リアルタイム自動収集"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/automatic.png"
                  />
                  <h3 className="hs__feature-card-title">
                    リアルタイム自動収集
                  </h3>
                  <p className="hs__feature-card-text">
                    収集開始後、自動でシステムが特定のハッシュタグをリアルタイムで収集します。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="on/offで投稿表示設定"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/choice.png"
                  />
                  <h3 className="hs__feature-card-title">
                    on/offで投稿表示設定
                  </h3>
                  <p className="hs__feature-card-text">
                    公開するかどうかを設定できます。基本はoffに設定されているので、不適切な画像があっても表示はされません。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="抽選機能"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/lottery.png"
                  />
                  <h3 className="hs__feature-card-title">抽選機能</h3>
                  <p className="hs__feature-card-text">
                    当選者を自動で抽選します。
                    <br />
                    後日抽選や即時抽選の選択も可能です。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="DM送信機能"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/email.png"
                  />
                  <h3 className="hs__feature-card-title">DM送信機能</h3>
                  <p className="hs__feature-card-text">
                    当選結果通知としてユニークなギフトコード、URLを送ることが可能です。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="並び順をカスタマイズ"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/sortby.png"
                  />
                  <h3 className="hs__feature-card-title">
                    並び順をカスタマイズ
                    <br />
                    <span>※オプション</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    投稿日時やいいねの獲得数などで、並び順をカスタマイズが可能です。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <img
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="デザインのカスタマイズ"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/design.png"
                  />
                  <h3 className="hs__feature-card-title">
                    デザインのカスタマイズ
                    <br />
                    <span>※オプション</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    自社サイトやキャンペーンサイトにあったデザインでの提供も可能です。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <CvBtn />
          <section className="hs__sample">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Sample</h2>
                <p className="hs__subtitle">導入事例</p>
                <p className="hs__lead">
                  コードを埋め込むだけで
                  <br />
                  コンテンツを自動表示できます。
                </p>
              </div>
              {/* <div className="hs__sample-button-container">
                <button
                  className="show-btn"
                  style={isShowSampleA ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleA}
                >
                  Twitter
                </button>
                <button
                  className="show-btn"
                  style={isShowSampleB ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleB}
                >
                  Instagram
                </button>
              </div> */}
              <p className="hs__sample-instagram-title">
                Instagram #PARKLoTカフェ
              </p>
              {/* {isShowSampleA && <SampleCardsA />} */}
              {/* {isShowSampleB && <SampleCardsB />} */}
              { <SampleCardsB /> }
              {/* <div className="hs__sample-display">
                <div id="pa-hashtag-item" data-id="1592183"></div>
              </div> */}
            </div>
          </section>
          {/* <section className="hs__price">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Price</h2>
                <p className="hs__subtitle">料金</p>
                <p className="hs__lead">
                  継続的にUGC収集ができるように
                  <br />
                  お手頃な価格でご利用いただけます。
                </p>
              </div>
              <div className="hs__price-card-container">
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title">基本料金</h3>
                  <div className="hs__price-card-content">
                    <h4 className="hs__price-card-subtitle">初期費用</h4>
                    <p className="hs__price-card-price">
                      8<span>万円</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">月額</h4>
                    <p className="hs__price-card-price">
                      5<span>万円</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">合計</h4>
                    <p className="hs__price-card-price">
                      13<span>万円（税込143,000円）</span>
                    </p>
                  </div>
                </div>
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title hs__price-card-title--grey">
                    オプション
                  </h3>
                  <div className="hs__price-card-content">
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">いいね収集</h4>
                        <p className="hs__price-card-price">
                          3<span>万円</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          リプライ収集
                        </h4>
                        <p className="hs__price-card-price">
                          3<span>万円</span>
                        </p>
                      </div>
                    </div>
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          デザインカスタマイズ
                        </h4>
                        <p className="hs__price-card-price">
                          3<span>万円</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          並び順カスタマイズ
                        </h4>
                        <p className="hs__price-card-price">
                          3<span>万円</span>
                        </p>
                      </div>
                    </div>
                    <h4 className="hs__price-card-subtitle">
                      キャンペーンLP作成代行
                    </h4>
                    <p className="hs__price-card-price">
                      10<span>万円〜</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <CvBtn />
          <Service />
        </main>
        {/* <script
          type="text/javascript"
          src="https://hashtag-campaigns.s3.ap-northeast-1.amazonaws.com/script/sample/hashtag-tweet.min.js"
        ></script> */}
      </Layout>
    </>
  )
}

export default HashtagCampaign
