import React from "react"
// import { img } from "gatsby-plugin-image"

export const SampleCardsB = () => {
  return (
    <div className="m-auto p-4 overflow-y-scroll ugc-sample__container">
      <div className="w-full flex flex-col md:flex-row md:flex-wrap">
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/24077432_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/24677062_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/4702284_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/3026046_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/3659624_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
        <div className="ugc-sample__card rounded-lg mb-4 md:mr-4">
          <img
            src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/product/ugc_sample/24767382_m.jpg"
            layout="constrained"
            alt=""
            className="ugc-sample__card-img--rounded"
          />
        </div>
      </div>
    </div>
  )
}
